<template>
	<div class="ztnrbg">


	<div class="index_mv ztnr">
		<leftMeun></leftMeun>
		<div class="right_nr">
			<rightMeun></rightMeun>
	<div class="list category_news_article" style="border: 0;">
		<div class="content_wrapper" v-html="data.content"></div>
	</div>
	</div>
<newRightMeun></newRightMeun>
	</div>
</div>
</template>

<script>

	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
	import newRightMeun from '@/components/newRightMeun.vue'
		export default {
			components: {
				leftMeun,
				rightMeun,
                newRightMeun
			},
		
		data() {
			return {
				data: ''
			}
		},
		mounted() {
			var that=this
			that.$api.page({id:that.$route.params.newsid}).then(res => {
				that.$emit('getLoad', true);
				if(res.code==1){
					that.data=res.data
				}else{
					that.data='暂无介绍'
				}
				console.log(that.data)
			}).catch((error) => {
				// error
				console.log(error)
			})
		}
	}
</script>

<style>
	
</style>